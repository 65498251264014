body {
    font-family: Sans-Serif;
    margin: 0;
    color: #FFFFFF;
}

.container {
    /*background: rgb(9, 13, 121);
    background: linear-gradient(21deg, rgba(9, 13, 121, 1) 0%, rgba(61, 9, 121, 1) 45%, rgb(97 3 98) 100%);*/
    background-color: rgb(9 0 48);
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    overflow: hidden;
}

::-webkit-scrollbar {
    display: none;
}

h1, h3 {
    margin: 10px 0px 20px 0px;
}

#root {
    width: 60%;
    margin: 0;
    background-color: rgb(9 0 48);
    border-radius: 30px;
    padding: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    box-shadow: 0 0px 24px 0px rgb(127 30 223 / 100%);
    touch-action: none;
    overflow: hidden;
}

@media (max-aspect-ratio: 16/16) {
    #root {
        width: calc(125% - 47px);
        min-height: calc(125% - 47px);
        position: fixed;
        border-radius: 0px;
    }
}

.answers-box-wrapper {
    overflow: auto;
    scrollbar-width: 5px;
    background-color: rgb(38 21 114);
    border-radius: 20px;
    box-shadow: inset 0 5px 12px 0px rgb(0 0 0 / 30%);
    height: 235px;
    overscroll-behavior: contain;
}
@media (max-aspect-ratio: 16/16) {
    .answers-box-wrapper {
        height: 300px;
    }
}

.answers-box-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;

    transition: all .2s ease-in-out;
}

.answers-element {
    border: #7f1edf;
    border-style: solid;
    border-width: 2px; 
    border-radius: 20px;
    text-align: center;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 10px 12px 0px rgb(0 0 0 / 20%);
    transition: all .2s ease-in-out;
}

.answers-element > p {
    margin: 0;
    color: white;
    font-size: 18px;
    user-select: none;
    font-weight: bold;
}

.answers-element:hover {
    background-color: #7f1edf;
    box-shadow: 0 0px 12px 0 #7f1edf;
}

.answers-element:active {
    background-color: #b060ff;
    border-color: #b060ff;
}

.success-bar-bg {
    min-height: 10px;
    border-radius: 5px;
    background-color: rgb(38 21 114);
    display: flex;
    margin: 20px 30px 0px 30px;
}

.success-bar {
    background-color: #7f1edf;
    transition: all 1s ease-in-out;
    border-radius: 5px;
}

.text-success {
    font-size: 20px;
    text-align: right;
    margin: 10px 30px 0px 30px;
}

.division {
    min-height: 2px;
    background-color: rgb(38 21 114);
    margin: 40px -10px 0px -10px;
}

.text-correct-answer {
    font-size: 20px;
    text-align: left;
    margin: 20px 10px 0px 10px;
    font-weight: bold;
    min-height: 34px;
}

.credit-content {
    position: fixed;
    left: 100%;
    top: 100%;

    transform: translate(calc(-100% - 10px), calc(-100% - 10px));
    background-color: #090030;
    border-radius: 20px;
    padding: 2px 10px;

    /*min-width: 20px;*/
    height: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.credit-content:hover #hide-without-hover, .credit-content #hide-on-hover {
    display: flex;
}
.credit-content:hover #hide-on-hover, .credit-content #hide-without-hover {
    display: none;
}
@media (max-aspect-ratio: 16/16) {
    #root {
        width: calc(125% - 47px);
        min-height: calc(125% - 47px);
        position: fixed;
        border-radius: 0px;
    }

    .credit-content #hide-on-hover, .credit-content:hover #hide-on-hover {
        display: none;
    }
    .credit-content #hide-without-hover, .credit-content:hover #hide-without-hover {
        display: flex;
    }
}

.credit-links {
    text-decoration: none;
    color: #b060ff;
}
a:link, a:visited {
    color: #ffffff;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
